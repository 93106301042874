import React, { useState } from 'react';
import './contact.css';

import contactBg from '../../assets/contact-bg.svg';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors({ ...errors, name: '' });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setErrors({ ...errors, message: '' });
  };

  const validateForm = () => {
    let formValid = true;
    const newErrors = {
      name: '',
      email: '',
      message: '',
    };

    if (name.trim() === '') {
      newErrors.name = 'Please enter your name.';
      formValid = false;
    }

    if (email.trim() === '') {
      newErrors.email = 'Please enter your email.';
      formValid = false;
    } else {
      // Email validation using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Please enter a valid email address.';
        formValid = false;
      }
    }

    if (message.trim() === '') {
      newErrors.message = 'Please enter your message.';
      formValid = false;
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Form submission logic (replace this with your desired action, like sending the email to a server)
    console.log('Name:', name);
    console.log('Email:', email);
    console.log('Message:', message);

    // Optionally, you can clear the form fields after submission.
    setName('');
    setEmail('');
    setMessage('');

  };

  const namePlaceholder = errors.name ? 'Please enter your Name' : 'Name';
  const emailPlaceholder = errors.email ? 'Please enter your Email' : 'Email';
  const messagePlaceholder = errors.message ? 'Please enter your Message' : 'Message';


  return (
    <div className='contactContainer section__padding'>


      <div className='contact-body '>

        <div className='contact-image'>
          <img className='contactImageBg' src={contactBg} alt='contact' />
        </div>


        <div className='contactForm'>
          <form className='contactContent' onSubmit={handleSubmit}>
            <div className='contactTitle'>
              <div className='gradient__text'>
                <h1>Get in touch</h1>
              </div>
            </div>

            <input
              type='text'
              placeholder={namePlaceholder}
              value={name}
              onChange={handleNameChange}
              aria-label='Name'
              data-error={errors.name}
              className={errors.name ? 'error' : ''}
            />

            <input
              type='email'
              placeholder={emailPlaceholder}
              value={email}
              onChange={handleEmailChange}
              aria-label='Email'
              data-error={errors.email}
              className={errors.email ? 'error' : ''}
            />

            <textarea
              placeholder={messagePlaceholder}
              value={message}
              onChange={handleMessageChange}
              aria-label='Message'
              data-error={errors.message}
              className={errors.message ? 'error' : ''}
            />





            <div className='contactButton'>
              <button className='contactSendButton' type='submit'>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
