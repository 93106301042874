import React from 'react'
import { Navbar } from '../components'
import { Footer } from '../containers'
import HeaderComponent from '../components/headerComponent/HeaderComponent'
import './aboutPage.css';
import aboutImage from '../assets/aboutus.png'

const trustees = [
  'Basim Matrood - Chairman',
  'Aga Mashallah Razvi',
  'Suhail Haider',
  'Haitham Alkhafaji',
  'Mojtaba Elahy',
  'Husnain Ahmed',
  'Sarwat Hussain',
];

const executives = [
  'Akbar Muhibi - President',
  'Sabahat Hussain',
  'Mohamed Abbas Roshanali',
];

const AboutPage = () => {
  return (
    <div>
      <Navbar />

      <HeaderComponent
        title={'About Us'}
        description={'We are a non-profit organization that is dedicated to serving the Muslim community of the greater Richmond area.'}
        image={aboutImage}
        imagePosition='left'
        titlePosition='right'
      />
      

      <div className='background-main-container'>
        <div className='background-header'>
          <h1 className='gradient__text'>Background</h1>
        </div>
        <div className='background-content'>
          <p>
            The Zahra Foundation was established in 1999 to serve the religious needs of the Muslim Shia community in Central Virginia. The members of the Foundation come from various nationalities and have faith in our religion and love for Ahlulbaith.<br />

            The Foundation conducts religious and other community services that include Salaat, Dua-e-Kumayl, birth, and martyrdom events of our masoomeen, marriages, funerals, charity to the needy, inter-faith activities etc. The Foundation also operates The Zahra Academy (weekend school for Islamic studies) that was started in 2007. <br />

            Until 2002, we had very few families and conducted our religious activities intermittently in people's houses. Since 2003, as the community grew, we moved towards renting of community centers.
          </p>
        </div>
      </div>
      <section className="goalsMainContainer section__padding">
        <h1 className="gradient__text">Aims and Objectives</h1>
        <div className="goalsTextContainer">
          <p>
            To provide guidance based on teachings of The Holy Quran, The Sunnah of The Holy Prophet Mohammed (s.a.w) as explained and interpreted by the Holy Ahlul Bayt.
          </p>
          <p>
            To organize and operate Sunday school for Islamic studies for children and adults.
          </p>
          <p>
            To serve as a center for Muslims in multicultural religious, social, and traditional activities.
          </p>
          <p>
            Based on the teachings of Islam, which include good citizenship and service to mankind, assist the needy, orphans and widows.
          </p>
          <p>
            Respect other faiths and foster the development of interfaith understanding.
          </p>
        </div>
      </section>

      {/* Board Of Trustees */}
      <div className='info-main-container section__padding'>
        <div className='info-header'>
          <h1 className='gradient__text'>BOARD OF TRUSTEES</h1>
        </div>
        <div className='info-content'>
          {trustees.map((trustee, index) => (
            <div key={index} className='info-card'>
              <h2>{trustee}</h2>
            </div>
          ))}
        </div>
      </div>

      {/* Executive Board */}
      <div className='info-main-container section__padding'>
        <div className='info-header'>
          <h1 className='gradient__text'>EXECUTIVE BOARD</h1>
        </div>
        <div className='info-content'>
          {executives.map((executive, index) => (
            <div key={index} className='info-card'>
              <h2>{executive}</h2>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;