import React from 'react';
import './headerComponent.css';

const HeaderComponent = ({ title, description, image, titlePosition = 'left', imagePosition = 'right', alt }) => {
  const isTitleOnLeft = titlePosition === 'left';
  const isImageOnRight = imagePosition === 'right';

  return (
    <div className={`zf__header ${isTitleOnLeft ? 'title-left' : 'title-right'}`} id="home">
      <div className="zf__header-content">
        <h1 className="gradient__text">{title}</h1>
        <p>{description}</p>
      </div>
      <div className={`zf__header-image ${isImageOnRight ? 'image-right' : 'image-left'}`}>
        <img src={image} alt={alt} />
      </div>
    </div>
  );
};

export default HeaderComponent;
