import React from 'react';
import { Link } from 'react-router-dom';
import { Contact, Navbar, Newsleeter, PrayerTime, HeaderComponent, Events } from '../components';
import { Footer } from '../containers';
import homeHeaderImage from '../assets/masjid2.svg';
import './homePage.css';

function HomePage() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="home-page">
      <Navbar />
      <HeaderComponent
        title="Our Mission"
        description="To provide an Islamic environment for the community to conduct religious, educational, cultural and social activities and to be good citizens by assisting the needy"
        image={homeHeaderImage}
        titlePosition="left"
        imagePosition="right"
      />
      <PrayerTime />

      <section className="about-main-body section__padding">
        <div className="about-content-body">
          <h1 className="gradient__text">About The Zahra Foundation</h1>
          <p>
            The Zahra Foundation was established in 1999 to serve the religious needs of the Muslim Shia community in Central Virginia.
            The members of the Foundation come from various nationalities and have faith in our religion and love for Ahlulbaith.
            The Foundation conducts religious and other community services that include Salaat, Dua-e-Kumayl, birth and martyrdom events of our masoomeen, marriages, funerals, charity to the needy, inter-faith activities etc. The Foundation also operates The Zahra Academy (weekend school for Islamic studies) that was started in 2007.
          </p>
          <div className="about-button">
            <Link to="/about">
              <button className="anotherButton" onClick={scrollToTop}>Learn More</button>
            </Link>
          </div>
        </div>
      </section>
      <Events />
      <Contact />
      <Newsleeter />
      <Footer />
    </div>
  );
}

export default HomePage;
