import React from "react";
import './prayerTimes.css';

const convertToStandardTime = (time) => {
    const [hour, minute] = time.split(':');
    const standardHour = parseInt(hour) >= 10 ? parseInt(hour) - 12 : hour;
    return `${standardHour}:${minute} ${parseInt(hour) >= 12 ? 'PM' : 'AM'}`;
}

const PrayerTimeCard = ({ praytimes }) => {
    return (
        <>
            {praytimes.length > 0 ? praytimes.map((time, index) => (
                <div className="prayer-times" key={index}>
                    <div className="prayingBox">
                        {Object.entries(time).map(([prayerName, prayerTime]) => (
                            <div className="titleTimeBox" key={prayerName}>
                                <div className="titleBox">
                                    <h1>{prayerName}</h1>
                                </div>
                                <div className="time-box">
                                    <h1>{convertToStandardTime(prayerTime)}</h1>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))
                :
                <h1 className="prayer-time-error">Sorry! Prayer Times Not Available at the Moment</h1>
            }
        </>
    );
};

export default PrayerTimeCard;
