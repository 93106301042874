import React from 'react';
import { Navbar } from '../components';
import { Donation } from '../components';
import { Footer } from '../containers';
import HeaderComponent from '../components/headerComponent/HeaderComponent';
import DonationImage from '../assets/Donate.svg';
import ZelleImage from '../assets/zelle.png';
import MailImage from '../assets/mail.png';

import './donationPage.css';


function donationPage() {
  return (
    <div>
      <Navbar />
      <HeaderComponent
        title="Support Our Masjid"
        description="Whether you're helping fellow Muslims facing challenges or supporting valuable Islamic initiatives, your donation holds great value"
        image={DonationImage}
        titlePosition="left"
        imagePosition="right"
      />
      <div className='section__padding'>
        <div className='quote-container' >
          <div className='arabic-text'><h2 className='' >إِنَّ الْمُصَّدِّقِينَ وَالْمُصَّدِّقَاتِ وَأَقْرَضُوا اللَّهَ قَرْضًا حَسَنًا يُضَاعَفُ لَهُمْ وَلَهُمْ أَجْرٌ كَرِيمٌ</h2></div>
          <div className='english-text'><h2 className=''>“For those who give in charity, men and women, and loan to Allah a Beautiful Loan, it shall be increased manifold (to their credit) and they shall have (besides) a liberal reward.” Al-Hadid [57:18]</h2></div>
        </div>
        <div className='' >
          <div className='arabic-text'><h2 className='' >إِنَّمَا يَعْمُرُ مَسَاجِدَ اللّهِ مَنْ آمَنَ بِاللّهِ وَالْيَوْمِ الآخِرِوَأَقَامَ الصَّلاَةَ وَآتَى الزَّكَاةَ وَلَمْ يَخْشَ إِلاَّ اللّه فَعَسَى أُوْلَـئِكَ أَن يَكُونُواْ مِنَ الْمُهْتَدِينَ َ</h2></div>
          <div className='english-text'><h2 className=''>“The Mosques of Allâh shall be maintained only by those who believe in Allâh and the Last Day; perform As-Salât, and give Zakât and fear none but Allâh. It is they who are expected to be on true guidance.” Al Tawbah [9:18]</h2></div>
        </div>

        <Donation
          imagePath={ZelleImage}
          imageAlt="Zelle Image"
          title="Donate by Zelle"
          description="You can conveniently donate using Zelle by sending your contribution to our registered Zelle email:
           events@zahra-foundation.org. Please include your name and purpose of donation in the memo section. May your generosity be rewarded."
          additionalInfo="Note: Ensure the Zelle transfer references 'Sadaqah' or 'Donation' for proper allocation."
        />
        <Donation
          imagePath={MailImage}
          imageAlt="Mail Image"
          title="Donate by Mail"
          description="If you prefer to donate by mail, you can send your contribution to the address below. Please make checks payable to Zahra Foundation. May your sincere efforts bring blessings to those in need."
          additionalInfo="Address: 963 Manakin Rd, Manakin-Sabot, VA 23103"
        />
      </div>

      <Footer />
    </div>
  )
}

export default donationPage;