import React from "react";

const LocationDetail = ({ coordinates }) => {
    return (
        <div className="prayer-info">
            {coordinates?.locations ?

                <p>Location: {coordinates.locations[0].address.city}, {coordinates.locations[0].address.state}, {coordinates.locations[0].address.countryName}</p>

                :
                <div>
                    <p>Location: Zahra foundation</p>
                </div>
            }
            <p>Note: Prayer Times are provided by Leva Institute, Qum</p>
        </div>
    );
};

export default LocationDetail;
