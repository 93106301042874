import React, { useEffect, useState } from "react";
import PrayerTimeCard from "./PrayerTimeCard";
import LocationDetail from "./LocationDetail";
import ErrorMessage from "./ErrorMessage";

import './prayerTimes.css';



const convertToStandardTime = (time) => {
    const [hour, minute] = time.split(':');
    const standardHour = (parseInt(hour) % 12) || 12;
    return `${standardHour}:${minute} ${parseInt(hour) >= 12 ? 'PM' : 'AM'}`;
};

const PrayerTimes = () => {
    const [praytimes, setPrayTimes] = useState([]);
    const [coordinates, setCoordinates] = useState(null);
    const [selectedState, setSelectedState] = useState('Virginia');
    const [selectedCity, setSelectedCity] = useState('Richmond');
    const [invalidLocation, setInvalidLocation] = useState(false);
    const [upcomingPrayer, setUpcomingPrayer] = useState(null);

    useEffect(() => {
        getPrayerTimes();
    }, []);

    const getPrayerTimes = async () => {
        try {
            const response = await fetch(`https://api.aladhan.com/v1/timingsByAddress?address=${selectedCity},${selectedState}&method=0`);
            const { data } = await response.json();

            if (data && data.timings) {
                const { Fajr, Sunrise, Dhuhr, Asr, Sunset, Maghrib, Isha, Midnight } = data.timings;
                setPrayTimes([{ Fajr, Sunrise, Dhuhr, Asr, Sunset, Maghrib, Isha, Midnight }]);
            }
        } catch (error) {
            setPrayTimes([]);
        }
    };

    useEffect(() => {
        if (praytimes.length > 0) {

            const { Fajr, Sunrise, Dhuhr, Asr, Sunset, Maghrib, Isha, Midnight } = praytimes[0];

            const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour12: false });
            const currentHour = parseInt(currentTime.split(':')[0]);
            const currentMinute = parseInt(currentTime.split(':')[1]);

            const fajrHour = parseInt(Fajr.split(':')[0]);
            const fajrMinute = parseInt(Fajr.split(':')[1]);
            const fajrTime = convertToStandardTime(Fajr);
            const dhuhrTime = convertToStandardTime(Dhuhr);
            const asrTime = convertToStandardTime(Asr);
            const maghribTime = convertToStandardTime(Maghrib);
            const ishaTime = convertToStandardTime(Isha);


            if (currentHour < fajrHour || (currentHour === fajrHour && currentMinute < fajrMinute)) {
                setUpcomingPrayer({ prayer: 'Fajr', time: fajrTime });
            } else if (currentHour < parseInt(Dhuhr.split(':')[0]) || (currentHour === parseInt(Dhuhr.split(':')[0]) && currentMinute < parseInt(Dhuhr.split(':')[1]))) {
                setUpcomingPrayer({ prayer: 'Dhuhr', time: dhuhrTime });
            } else if (currentHour < parseInt(Asr.split(':')[0]) || (currentHour === parseInt(Asr.split(':')[0]) && currentMinute < parseInt(Asr.split(':')[1]))) {
                setUpcomingPrayer({ prayer: 'Asr', time: asrTime });
            } else if (currentHour < parseInt(Maghrib.split(':')[0]) || (currentHour === parseInt(Maghrib.split(':')[0]) && currentMinute < parseInt(Maghrib.split(':')[1]))) {
                setUpcomingPrayer({ prayer: 'Maghrib', time: maghribTime });
            } else if (currentHour < parseInt(Isha.split(':')[0]) || (currentHour === parseInt(Isha.split(':')[0]) && currentMinute < parseInt(Isha.split(':')[1]))) {
                setUpcomingPrayer({ prayer: 'Isha', time: ishaTime });
            } else {
                // Handle the scenario where the current time is after all prayer times
                setUpcomingPrayer({ prayer: 'Fajr', time: fajrTime });
            }
        }
    }, [praytimes]);

    return (
        <>
            <div className="upcoming-prayer-container">
                {upcomingPrayer && (
                    <h1 className="upcoming-prayer"> Upcoming Prayer Time: {upcomingPrayer.prayer} - {(upcomingPrayer.time)}</h1>
                )}
            </div>
            <div className="main-container section__padding">
                <div className=""><h1 className="prayer-title-header light-gradient__text">Prayer Times</h1></div>
                <PrayerTimeCard praytimes={praytimes} />
                <LocationDetail coordinates={coordinates} />
            </div>

        </>
    );
};

export default PrayerTimes;
