import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';

const navigationLinks = [
  { to: '/', text: 'Home' },
  { to: '/about', text: 'About' },
];

const MenuIcon = ({ isOpen, onClick }) => {
  if (isOpen) {
    return <RiCloseLine color="#fff" size={27} onClick={onClick} />;
  } else {
    return <RiMenu3Line color="#fff" size={27} onClick={onClick} />;
  }
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navMenuRef = useRef(null);
  const location = useLocation();

  const closeMenu = () => {
    setToggleMenu(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="zf__navbar">
      <div className="zf__navbar-links">
        <div className="zf__navbar-links_logo">
          <h1>The Zahra Foundation</h1>
        </div>
        <div className="zf__navbar-links_container">
          {navigationLinks.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              onClick={() => {
                closeMenu();
                scrollToTop();
              }}
              className={location.pathname === link.to ? 'active-link' : ''}
            >
              <p>{link.text}</p>
            </Link>
          ))}
        </div>
      </div>
      <div className="zf__navbar-sign">
        <Link to="/donation" onClick={() => { closeMenu(); scrollToTop(); }}>
          <button className="donation-button" type="button">
            Donate
          </button>
        </Link>
      </div>

      <div className="zf__navbar-menu" ref={navMenuRef} onClick={null}>
        <MenuIcon isOpen={toggleMenu} onClick={() => setToggleMenu(!toggleMenu)} />
        {toggleMenu && (
          <div className="zf__navbar-menu_container slide-in-right">
            <div className="zf__navbar-menu_container-links">
              {navigationLinks.map((link, index) => (
                <Link key={index} to={link.to} onClick={() => { closeMenu(); scrollToTop(); }}>
                  <p>{link.text}</p>
                </Link>
              ))}
            </div>
            <div className="zf__navbar-menu_container-links-sign">
              <Link to="/donation">
                <button type="button" className="glowing-btn">
                  Donate
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
