import React from 'react';
import { Link } from 'react-router-dom';

import './donation.css'; 

function Donation(props) {
  return (
    <div className="donation-option">
      <div className="option-image">
        <img src={props.imagePath} alt={props.imageAlt} />
      </div>
      <div className="option-info">
        <h2 className="option-title">{props.title}</h2>
        <p className="option-description">{props.description}</p>
        {props.additionalInfo && <p className="option-additional-info">{props.additionalInfo}</p>}
      </div>
    </div>
  );
}

export default Donation;
