import React, { Component } from 'react';
import './Events.css';

class Events extends Component {
  state = {
    events: [],
  };

  componentDidMount() {
    this.fetchCalendarEvents();
  }

  fetchCalendarEvents = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
      const calendarId = process.env.REACT_APP_GOOGLE_CALENDAR_ID;
      const currentDate = new Date();
      const futureDate = new Date(currentDate);
      futureDate.setDate(futureDate.getDate() + 12); // 12 days in the future

      const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}&timeMin=${currentDate.toISOString()}&timeMax=${futureDate.toISOString()}`;

      const response = await fetch(url);
      const data = await response.json();

      if (data.items) {
        const upcomingEvents = data.items;

        // Sort events by start date, from soonest to latest
        upcomingEvents.sort((a, b) => {
          const dateA = new Date(a.start.dateTime || a.start.date);
          const dateB = new Date(b.start.dateTime || b.start.date);
          return dateA - dateB;
        });

        this.setState({ events: upcomingEvents });
      }
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  formatTime = (date) => {
    return new Date(date).toLocaleTimeString([], { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit' });
  };

  render() {
    const { events } = this.state;

    return (
      <div className="upcoming-events-container">
        <h1 className='events-header gradient__text'>Upcoming Events</h1>
        {events.length === 0 ? (
          <p className="no-events-message">No upcoming events for today.</p>
        ) : (
          <div className="event-list">
            {events.map((event) => {
              const eventStartDate = new Date(event.start.dateTime || event.start.date);
              const day = eventStartDate.getDate();
              const month = eventStartDate.toLocaleString('default', { month: 'long' });
              const year = eventStartDate.getFullYear();
              const isAllDay = !event.start.dateTime;

              return (
                <div className="event-card" key={event.id}>
                  <div className="event-date">
                    <div className="event-day">{day}</div>
                    <div className="event-month">{month}</div>
                    <div className="event-year">{year}</div>
                  </div>
                  <div className="event-details">
                    <h2 className='event-title'>{event.summary}</h2>
                    <div className='event-time'>
                      {isAllDay ? (
                        <p className='start-time'>
                          <strong>Duration:</strong> All Day
                        </p>
                      ) : (
                        <>
                          <p className='start-time'>
                            <strong>From:</strong> {this.formatTime(event.start.dateTime)}
                          </p>
                          {event.end.dateTime && (
                            <p>
                              <strong> To:</strong> {this.formatTime(event.end.dateTime)}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    {event.description && (
                      <p>
                        <strong>Description:</strong> {event.description}
                      </p>
                    )}
                    {event.location && (
                      <p>
                        <strong>Location:</strong> {event.location}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default Events;
