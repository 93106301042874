import React from 'react';
// import gpt3Logo from '../../logo.svg';
import './footer.css';


const Footer = () => (
  <div className="zf__footer section__padding">
    {/* <div className="zf__footer-heading">
      <h1 className="gradient__text">Subscribe to our newsletter</h1>
    </div>
    <div className="zf__footer_input">
      <input type={'email'} placeholder="Email" />
      <button type="button">Subscribe</button>
    </div> */}

    <div className="zf__footer-links">
      <div className="zf__footer-links_logo">
        <h1>The Zahra Foundation</h1>
        {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
      </div>
      {/* <div className="zf__footer-links_div">
        <h4>Links</h4>
        <p><a href='#'>Facebook</a></p>
        <p><a href='https://www.instagram.com/zahrafoundationva/'>Instagram</a></p>
      </div> */}


      <div className="zf__footer-links_div">
        <h4>Get in touch</h4>
        <p>963 Manakin Rd, Manakin-Sabot, VA 23103</p>
        <p>events@zahra-foundation.org</p>
      </div>
    </div>

    <div className="zf__footer-copyright">
      <p>The Zahra Foundation @2016. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
