import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import home from './Pages/Home';
import donation from './Pages/Donation';
import about from './Pages/About';

import './App.css';
import { Navbar } from './components';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' Component={home} />
          <Route path='/donation' Component={donation} />
          <Route exact path='/about' Component={about} />
        </Routes>
      </Router>
    </div>
  )
}

export default App;