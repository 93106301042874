// src/components/Newsletter.jsx
import React, { useState } from 'react';
import './newsletter.css';

function Newsletter() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputError, setInputError] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setInputError(false); // Reset input error state when the user types
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage('Invalid email address');
      setInputError(true); // Set input error state to true
    } else {
      setSubscribed(true);
      setErrorMessage('');
      console.log(`Subscribed with email: ${email}`);
      setEmail(''); // Clear the email input on successful submission
      setInputError(false); // Reset input error state
    }
  };

  return (
    <div className={`zf__newsletter section__padding ${subscribed ? 'subscribed' : ''}`}>
      <div className="newsletter-heading">
        <h1 className="gradient__text">Subscribe to our newsletter</h1>
      </div>
      {subscribed ? (
        <p className="subError-message">
          Sorry! Something went wrong. Please Try Again Later. 
        </p>
      ) : (
        <form className="newsletter-input" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder={inputError ? 'Please enter an email address' : 'Email'}
            value={email}
            onChange={handleEmailChange}
            required
            className={inputError ? 'error' : ''}
          />
          <button type="submit">Subscribe</button>
        </form>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default Newsletter;
